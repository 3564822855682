/* eslint-disable max-statements */
import React, { useState } from "react";
import { navigate } from "gatsby";
import { Auth } from "@aws-amplify/auth";
import { showToast } from "@jobber/components/Toast";
import { useUserContext } from "@/context/user";
import { ErrorMessages } from "@/utils/cognito/ErrorMessages";
import { useDisplayError } from "@/hooks/useDisplayError";
import { MFAPrompt } from "./MFAPrompt";

export const MFAPromptLoader = () => {
  const [isLoadingConfirmation, setIsLoadingConfirmation] = useState(false);
  const { displayError } = useDisplayError();
  const { cognitoUser } = useUserContext();
  const [invalid, setInvalid] = useState(false);

  return (
    <MFAPrompt
      invalid={invalid}
      isLoadingConfirmation={isLoadingConfirmation}
      onCodeSubmit={handleCodeSubmit}
    />
  );

  async function handleCodeSubmit(code: string) {
    setIsLoadingConfirmation(true);
    try {
      await Auth.confirmSignIn(cognitoUser, code, "SOFTWARE_TOKEN_MFA");
      showToast({
        message: `Logged in`,
        variation: "success",
      });
      navigate("/");
    } catch (error: any) {
      const message = error?.message;
      switch (message) {
        case ErrorMessages.INVALID_MFA_CODE: {
          setInvalid(true);
          break;
        }
        case ErrorMessages.INVALID_SESSION_EXPIRED: {
          displayError([`Session expired, login again`], false);
          navigate("/login");
          break;
        }
      }
    } finally {
      setIsLoadingConfirmation(false);
    }
  }
};
