import React from "react";
import { Page } from "@/components/Page";
import { MFAPrompt } from "@/features/Authentication/MFAPrompt";

const MFAPage = () => {
  return (
    <Page variation="presession">
      <MFAPrompt />
    </Page>
  );
};

export default MFAPage;
