import React, { useState } from "react";
import { InputText } from "@jobber/components/InputText";
import { Content } from "@jobber/components/Content";
import { Button } from "@jobber/components/Button";
import { Heading } from "@jobber/components/Heading";
import { Text } from "@jobber/components/Text";
import { Form } from "@jobber/components/Form";
import { InputValidation } from "@jobber/components/InputValidation";
import * as styles from "@/features/Authentication/PresessionCommon/PresessionCommon.module.css";
import { JobberDevCenterLabel } from "../components/JobberDevCenterLabel";

interface MFAPromptProps {
  isLoadingConfirmation: boolean;
  invalid: boolean;
  onCodeSubmit(code: string): void;
}

export const MFAPrompt = ({
  isLoadingConfirmation,
  invalid,
  onCodeSubmit,
}: MFAPromptProps) => {
  const [inputs, setInputs] = useState({
    code: "",
  });

  return (
    <div className={styles.common_wrapper}>
      <JobberDevCenterLabel />
      <Form onSubmit={handleCodeConfirmationAttempt}>
        <Content>
          <Heading level={2}>Two-Factor Authentication</Heading>
          <Text>
            Enter the 6-digit code displayed in your authenticator app below:
          </Text>
          <div>
            <div className={styles.validation_message}>
              <InputText
                placeholder="Authentication code"
                size="large"
                invalid={invalid}
                validations={{
                  required: {
                    value: true,
                    message: "Please provide your Authentication code",
                  },
                }}
                onChange={handleInputChange({ input: "code" })}
              />
            </div>
            {invalid && (
              <InputValidation message="Code does not match. Please try again" />
            )}
          </div>
          <Button
            label="Continue"
            size="large"
            fullWidth
            submit
            loading={isLoadingConfirmation}
          />
        </Content>
      </Form>
    </div>
  );

  function handleInputChange({ input }: { input: "code" }) {
    return (value: string) =>
      setInputs(current => ({ ...current, [input]: value }));
  }

  function handleCodeConfirmationAttempt() {
    const { code } = inputs;
    onCodeSubmit(code);
  }
};
